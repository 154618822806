import { createSelector } from 'reselect';
import {
  IActivityLog,
  IActivityLogState,
} from 'src/app/interfaces/IActivityLog';
import { sortTableData } from 'src/app/utils/shared';
import { formatTimestamp, getLogUsersEmails, getSubTypes } from './helpers';
import { IState } from '../../core/redux/interfaces';
import 'core-js/features/set';
import { getAllUsers } from '../common/selectors';

export const getState = (state: IState): IActivityLogState =>
  state.activityLog || {};

export const getAppliedFilters = createSelector(
  [getState],
  ({ appliedFilters }) => appliedFilters
);

export const getActivityLogData = createSelector(
  [getState],
  ({ activityLogData }) => activityLogData
);

export const getIsActivityLogFilterDrawerVisible = createSelector(
  [getState],
  ({ isActivityLogFilterDrawerVisible }) => isActivityLogFilterDrawerVisible
);

export const getInitialDrawerFilters = createSelector(
  [getState],
  ({ initialDrawerFilters }) => initialDrawerFilters
);

export const getUserSelectedDrawerFilters = createSelector(
  [getState],
  ({ userSelectedDrawerFilters }) => userSelectedDrawerFilters
);

export const getIsUserTouchedDateRangeFilter = createSelector(
  [getState],
  ({ hasUserTouchedDateRangeFilter }) => hasUserTouchedDateRangeFilter
);

export const getDrawerSubtypesOptions = createSelector(
  [getState],
  ({ userSelectedDrawerFilters }) =>
    getSubTypes(userSelectedDrawerFilters.userSelectedEventSubTypes)
);

export const getLogUsersOptions = createSelector(
  [getState, getAllUsers],
  ({ userSelectedDrawerFilters }, allUsers) =>
    getLogUsersEmails(allUsers, userSelectedDrawerFilters.userSelectedUsers)
);

export const getActivityLogsRequestDate = createSelector(
  [getState],
  ({ activityLogRequestDate }) => {
    if (activityLogRequestDate.length === 0) {
      return {
        date: '',
        time: '',
      };
    }
    const convertedDate = formatTimestamp(activityLogRequestDate).split(', ');
    return {
      date: convertedDate[0],
      time: convertedDate[1],
    };
  }
);

export const getActivityLogsRequestDatePast = createSelector(
  [getState, getInitialDrawerFilters],
  ({ activityLogRequestDate, initialDrawerFilters }) => {
    const initialDate = initialDrawerFilters.initialFetchedRange?.[1];

    const activityLogRequestDatePast = new Date(activityLogRequestDate);
    const initialFetchedRange = initialDate ? new Date(initialDate) : null;

    const twelveHoursInMs = 12 * 60 * 60 * 1000;

    if (!initialFetchedRange) {
      return false;
    }

    const timeDifference =
      activityLogRequestDatePast.getTime() - initialFetchedRange.getTime();

    return timeDifference >= twelveHoursInMs;
  }
);

export const getSearchField = createSelector(
  [getState],
  ({ searchField }) => searchField
);

export const getActivityLogInitialSortingState = createSelector(
  [getState],
  ({ sortingOptions }) => sortingOptions
);

export const getSortedActivityLogData = createSelector(
  [getState, getActivityLogData],
  ({ sortingOptions }, { data: { logs } }) => {
    let activityLogData: IActivityLog[];
    if (!logs) {
      activityLogData = [];
    } else {
      activityLogData = logs;
    }
    const sortedData = sortTableData(activityLogData, sortingOptions);
    return sortedData;
  }
);

export const getIsUserTouchedSelectorsFilter = createSelector(
  [getState],
  ({ hasUserTouchedSelectorsFilter }) => hasUserTouchedSelectorsFilter
);

export const getUserSelectedOptions = createSelector(
  [getState],
  ({ userSelectedDrawerFilters }) => userSelectedDrawerFilters
);
